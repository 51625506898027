<template>
  <article v-if="car" class="lg:first:mt-0 first:mt-0 mt-2 lg:mt-4">
    <header class="flex justify-between text-black font-bold lg:items-center">
      <div>
        <h3 class="text-xl capitalize">
          {{ car.manufacturer }} {{ car.model }}
          <span v-if="car.year" class="text-base font-normal">({{ car.year }})</span>
        </h3>
        <h4 v-if="car.colour" class="text-lg capitalize">{{ car.colour }}</h4>
      </div>
      <div class="flex gap-x-2 items-center mb-4 mt-1 sm:justify-end sm:mb-0 sm:mt-0">
        <button
          v-if="isCarOwner"
          title="Edit car"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            w-8
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            rounded-full
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="buildForm()"
        >
          <BaseIcon class="w-6 h-6"><EditIcon /></BaseIcon>
        </button>
        <button
          v-if="isCarOwner"
          title="Delete car"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            w-8
            h-8
            focus:bg-gray
            bg-transparent
            border border-gray
            rounded-full
            focus:outline-none
            shadow-none
            transition-colors
            duration-150
            ease-in-out
            focus:ring-0
          "
          @click.prevent="deleteCar()"
        >
          <BaseIcon class="w-6 h-6"><DeleteIcon /></BaseIcon>
        </button>
      </div>
    </header>
    <div class="align-center flex mt-1">
      <BaseIcon class="mr-1">
        <EventIcon />
      </BaseIcon>
      <div>
        <AtomFormattedDate hide-day :date="car.bought_date" /> -
        <AtomFormattedDate v-if="car.sold_date" hide-day :date="car.sold_date" /><template v-else>Current</template>
      </div>
    </div>
    <div v-if="description" class="mt-2" v-html="description"></div>
  </article>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      car: Object
    },
    emits: ['deleted'],
    data() {
      return {
        actionTitle: 'Update car',
        form: {
          cta: 'Update car',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Car Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'manufacturer',
              label: 'Manufacturer',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'model',
              label: 'Model',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'year',
              label: 'Year',
              elem: 'input',
              type: 'number',
              min: '1900',
              max: new Date().getFullYear(),
              isRequired: null
            },
            {
              id: 'drive',
              label: 'Drive',
              elem: 'select',
              isRequired: 'true',
              children: [
                {
                  tag: 'option',
                  text: 'AWD/4WD',
                  value: 'awd'
                },
                {
                  tag: 'option',
                  text: 'FWD',
                  value: 'fwd'
                },
                {
                  tag: 'option',
                  text: 'RWD',
                  value: 'rwd'
                }
              ]
            },
            {
              id: 'power',
              label: 'Power (bhp)',
              elem: 'input',
              type: 'number',
              isRequired: null
            },
            {
              id: 'weight',
              label: 'Weight (kg)',
              elem: 'input',
              type: 'number',
              isRequired: null
            },
            {
              id: 'colour',
              label: 'Colour',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'start_date',
              label: 'Bought',
              elem: 'input',
              type: 'month',
              min: '2000-01',
              max: `${new Date().getFullYear()}-12`,
              isRequired: 'true'
            },
            {
              id: 'end_date',
              label: 'Sold',
              elem: 'input',
              type: 'month',
              min: '2000-01',
              max: `${new Date().getFullYear()}-12`,
              isRequired: null
            },
            {
              id: 'description',
              label: 'Description',
              elem: 'textarea',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      isCarOwner() {
        return this.car.user_uid === (this.loggedInUser && this.loggedInUser.id)
      },
      description() {
        return this.car.description
          ? this.car.description.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/\*([^\s][^\*]+?[^\s])\*/g, '<b>$1<\/b>')
          : null
      }
    },
    methods: {
      async deleteCar() {
        try {
          await this.apiRequest(`/car/${this.car.uid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          this.$store.dispatch('setDialog', {
            title: 'Car Deleted!',
            content: '<p>The car has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('deleted', this.car.uid)
        } catch (e) {
          if (e) console.log(`Error deleting car: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this car. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      async buildForm() {
        const boughtDate = this.car.bought_date.split('-')
        const soldDate = this.car.sold_date ? this.car.sold_date.split('-') : null

        this.form.initial = {
          name: this.car.car_name,
          manufacturer: this.car.manufacturer,
          model: this.car.model,
          year: this.car.year,
          colour: this.car.colour,
          start_date: `${boughtDate[0]}-${boughtDate[1]}`,
          end_date: soldDate ? `${soldDate[0]}-${soldDate[1]}` : soldDate,
          description: this.car.description,
          drive: this.car.drive,
          power: this.car.power,
          weight: this.car.weight
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          method: 'PUT',
          endpoint: `/car/${this.car.uid}`,
          errorMessage: "Sorry we couldn't edit your car.",
          successMessage: 'Your car has been updated.'
        })
      }
    }
  }
</script>
