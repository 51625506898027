<template>
  <component
    :is="type"
    :to="url"
    :disabled="disabled"
    :title="popupTitle ? popupTitle : title"
    :class="[
      'items-center justify-center rounded-sm border border-transparent transition-all ease-in-out duration-300 focus:outline-none',
      large ? 'lg:text-lg px-5 py-3' : 'mt-4 p-2',
      inline ? 'w-auto inline-flex px-4' : 'w-full flex',
      ghost
        ? 'text-black bg-white border-gray lg:hover:border-black'
        : !negativeAction
        ? secondary
          ? 'bg-white text-orange-500 lg:hover:bg-black lg:hover:text-white'
          : 'text-white bg-orange-500 border-orange-500 lg:hover:bg-orange-300 lg:hover:border-orange-300'
        : 'text-white',
      disabled ? 'cursor-default lg:hover:bg-orange-500' : null,
      negativeAction ? 'bg-gray text-black lg:hover:bg-black' : null,
      classes
    ]"
  >
    <span v-if="!isDoing">{{ title }}</span>
    <AtomLoader v-else></AtomLoader>
  </component>
</template>

<script>
  export default {
    props: {
      action: String,
      isDoing: {
        type: Boolean,
        default: false
      },
      classes: Array,
      title: String,
      popupTitle: String,
      type: {
        type: String,
        default: 'RouterLink'
      },
      url: {
        type: String,
        default: null
      },
      secondary: {
        type: Boolean,
        default: false
      },
      ghost: {
        type: Boolean,
        default: false
      },
      inline: {
        type: Boolean,
        default: false
      },
      large: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      negativeAction: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
