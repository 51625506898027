<template>
  <header class="mx-auto px-4 lg:px-8">
    <div
      :class="[
        'relative items-center justify-between mx-auto w-full sm:flex sm:h-10',
        !isFullWidth ? 'max-w-screen-2xl' : null
      ]"
    >
      <h2 class="flex text-black text-2xl font-bold tracking-tight leading-tight">
        <template v-if="isLoading">Loading…</template>
        <template v-else>
          {{ title }}
        </template>
        <slot></slot>
      </h2>

      <div v-if="!isLoading && actionTitle" class="mt-2 sm:mt-0">
        <button
          class="
            group
            lg:hover:bg-orange-300
            lg:hover:border-orange-300
            inline-flex
            items-center
            justify-center
            mt-2
            h-10
            text-white text-sm
            font-medium
            bg-orange-500
            border-0
            rounded-sm
            focus:outline-none
            overflow-hidden
            transform
            transition
            duration-300
            ease-in-out
            sm:mt-0
          "
          :title="actionTitle"
          @click.prevent="buttonAction()"
        >
          <BaseIcon class="mx-2 w-7 h-7 transform transition-all duration-300 ease-in-out">
            <PlusIcon></PlusIcon>
          </BaseIcon>
          <p class="mr-4 transition-all duration-300 ease-in-out">
            {{ actionTitle }}
          </p>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    props: {
      title: String,
      isLoading: Boolean,
      isFullWidth: Boolean,
      actionTitle: String
    },
    emits: ['action'],
    methods: {
      buttonAction() {
        this.$emit('action')
      }
    }
  }
</script>
