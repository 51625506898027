<template>
  <div class="my-4 px-4 lg:my-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <p>
        <template v-if="details.name"> {{ details.name }} <br /> </template>
        <template v-if="details.address_1"> {{ details.address_1 }} <br /> </template>
        <template v-if="details.address_2"> {{ details.address_2 }} <br /> </template>
        <template v-if="details.address_3"> {{ details.address_3 }} <br /> </template>
        <template v-if="details.town_city"> {{ details.town_city }} <br /> </template>
        <template v-if="details.post_code"> {{ details.post_code }} <br /> </template>
        <template v-if="details.country"> {{ details.country }} <br /> </template>
      </p>
      <p v-if="details.telephone">
        <a :href="`tel:${details.telephone}`">{{ details.telephone }}</a>
      </p>
      <p v-if="details.email">
        <a :href="`mailto:${details.email}`">{{ details.email }}</a>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      details: Object
    }
  }
</script>
