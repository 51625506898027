<template>
  <div class="relative">
    <div class="relative flex items-start space-x-4">
      <div class="relative">
        <img
          v-if="hasPhoto"
          class="flex items-center justify-center w-10 h-10 rounded-full ring-white ring-8"
          :src="userPhoto"
          :alt="comment.username"
        />
        <div v-else class="flex items-center justify-center p-2 w-10 h-10 bg-gray rounded-full"><AtomLogo /></div>
        <span class="absolute -bottom-1 -right-1 px-0.5 py-px text-black bg-white rounded-full">
          <BaseIcon class="text-gray-300"><CommentIcon /></BaseIcon>
        </span>
      </div>
      <div class="group flex-1 min-w-0 space-y-2">
        <div>
          <div class="flex items-center">
            <div class="flex-1 w-full space-y-0 lg:flex-none">
              <div
                class="relative block p-2 border rounded-sm lg:inline-block"
                :class="[highlight ? 'bg-gray-100 border-gray-100' : 'bg-gray-100 border-gray-100']"
              >
                <div>
                  <RouterLink
                    :to="`/${comment.username}`"
                    class="lg:hover:text-orange-500 font-bold transition-colors duration-150 ease-in-out"
                    active-class="text-black"
                  >
                    {{ comment.first_name }} {{ comment.last_name }}
                  </RouterLink>
                </div>
                <div
                  ref="Comment"
                  class="inline-block whitespace-pre-wrap outline-none"
                  :class="[hasLikes ? 'pr-12' : null]"
                  :contenteditable="isEditable"
                >
                  <p v-html="content"></p>
                </div>
                <div
                  v-if="hasLikes"
                  class="
                    absolute
                    -bottom-4
                    -right-0
                    flex
                    items-center
                    p-1
                    pr-2
                    text-sm
                    bg-white
                    rounded-sm
                    pointer-events-none
                  "
                >
                  <BaseIcon class="mr-1 w-6 h-6"><LoveIcon /></BaseIcon>
                  {{ likes }}
                </div>
              </div>
            </div>
          </div>
          <div class="relative flex items-center justify-between mt-1 pl-2 text-sm space-x-2">
            <div v-if="!isEditable" class="flex items-center space-x-2">
              <button
                v-if="loggedInUser"
                class="
                  lg:hover:border-black
                  font-bold
                  border-b border-transparent
                  focus:outline-none
                  opacity-50
                  transition-colors
                  duration-150
                  ease-in-out
                "
                :class="[liked ? 'text-orange-500 lg:hover:border-orange-500 opacity-100' : null]"
                :disabled="isLiking"
                :title="liked ? 'Unlike photograph' : 'Like photograph'"
                @click.prevent="likeComment"
              >
                Like
              </button>
              <button
                v-if="loggedInUser"
                class="
                  lg:hover:border-black
                  font-bold
                  border-b border-transparent
                  focus:outline-none
                  opacity-50
                  transition
                  duration-150
                  ease-in-out
                "
                :class="[isReplying ? 'border-black' : null]"
                title="Reply to comment"
                @click.prevent="toggleReplyComment"
              >
                Reply
              </button>
              <p>
                {{ date }}
              </p>
              <p v-if="comment.created_at !== comment.last_modified && !isCommentAuthor" class="text-xs opacity-50">
                Edited
              </p>
              <div v-if="loggedInUser" class="relative flex">
                <button
                  class="
                    lg:group-hover:opacity-100
                    focus:outline-none
                    opacity-50
                    transition
                    duration-150
                    ease-in-out
                    lg:opacity-0
                  "
                  :class="[isMenuVisible ? 'lg:opacity-100' : null]"
                  :title="isMenuVisible ? 'Hide actions' : 'View more actions'"
                  @click.prevent="toggleMenu"
                >
                  <BaseIcon class="w-6 h-6">
                    <DotsIcon />
                  </BaseIcon>
                </button>
                <div
                  v-if="isMenuVisible"
                  class="absolute z-10 right-0 top-full w-40 text-sm bg-white border border-gray rounded-sm"
                >
                  <ul class="py-2 space-y-2">
                    <li v-if="isCommentAuthor">
                      <button
                        class="
                          lg:hover:text-orange-500
                          flex
                          px-4
                          w-full
                          focus:border-orange-500
                          focus:outline-none
                          transition
                          duration-150
                          ease-in-out
                        "
                        @click.prevent="toggleEditComment"
                      >
                        Edit comment
                      </button>
                    </li>
                    <li v-if="isCommentAuthor">
                      <button
                        class="
                          lg:hover:text-orange-500
                          flex
                          px-4
                          w-full
                          focus:border-orange-500
                          focus:outline-none
                          disabled:cursor-default
                          transition
                          duration-150
                          ease-in-out
                        "
                        :disabled="isDeleting"
                        title="Delete comment"
                        @click.prevent="deleteComment"
                      >
                        Delete comment
                      </button>
                    </li>
                    <li v-if="!isCommentAuthor">
                      <button
                        class="
                          w-fullflex
                          lg:hover:text-orange-500
                          flex
                          items-center
                          px-4
                          focus:border-orange-500
                          focus:outline-none
                          disabled:cursor-default
                          transition
                          duration-150
                          ease-in-out
                        "
                        :disabled="isReporting"
                        title="Report comment"
                        @click.prevent="reportComment"
                      >
                        Report comment
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-if="isEditable" class="flex items-center h-6 space-x-2">
              <button
                class="
                  lg:hover:border-black
                  font-bold
                  border-b border-transparent
                  focus:outline-none
                  opacity-50
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="toggleEditComment"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <ul v-if="hasReplies" class="space-y-2">
          <li v-for="reply in comment.replies" :key="reply.uid">
            <AtomReply
              :reply="reply"
              :parent-uid="parentUid"
              :overlay-uid="overlayUid"
              :highlight="highlight"
              @deleted="updateCommentList"
              @reply="toggleReplyComment"
            />
          </li>
        </ul>
        <div v-if="isReplying" class="flex items-start mb-4">
          <div class="relative mt-2">
            <img
              v-if="hasCoverPhoto"
              class="flex items-center justify-center w-8 h-8 rounded-full ring-white ring-8"
              :src="coverPhoto"
              :alt="loggedInUser.username"
            />
            <div v-else class="flex items-center justify-center p-2 w-8 h-8 bg-gray rounded-full"><AtomLogo /></div>
          </div>
          <MoleculeForm
            :initial-values="form.initial"
            :fields="form.fields"
            :cta="form.cta"
            :classes="form.classes"
            :is-saving="isSaving"
            :reset="true"
            :inline-submit="true"
            @form-submitted="submitForm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as timeago from 'timeago.js'
  import Filter from 'bad-words'
  import AtomReply from '@/components/atoms/Reply.vue'

  const localeFunc = (number, index, totalSec) => {
    return [
      ['just now', 'right now'],
      ['%ss', 'in %ss'],
      ['1m', 'in 1m'],
      ['%sm', 'in %sm'],
      ['1h', 'in 1h'],
      ['%sh', 'in %sh'],
      ['1d', 'in 1d'],
      ['%sd', 'in %sd'],
      ['1w', 'in 1w'],
      ['%sw', 'in %sw'],
      ['1mo', 'in 1mo'],
      ['%smo', 'in %smo'],
      ['1yr', 'in 1yr'],
      ['%syr', 'in %syr']
    ][index]
  }

  function setFocus(el) {
    if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
      var range = document.createRange()
      range.selectNodeContents(el)
      range.collapse(false)
      var sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (typeof document.body.createTextRange != 'undefined') {
      var textRange = document.body.createTextRange()
      textRange.moveToElementText(el)
      textRange.collapse(false)
      textRange.select()
    }
  }

  function linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
    replacedText = inputText.replace(
      replacePattern1,
      '<a class="border-b border-b-gray" href="$1" target="_blank">$1</a>'
    )

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a class="border-b border-b-gray" href="http://$2" target="_blank">$2</a>'
    )

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
    replacedText = replacedText.replace(replacePattern3, '<a class="border-b border-b-gray" href="mailto:$1">$1</a>')

    return replacedText
  }

  timeago.register('en-short', localeFunc)

  export default {
    components: {
      AtomReply
    },
    props: {
      comment: Object,
      highlight: {
        type: Boolean,
        default: false
      },
      onFeed: {
        type: Boolean,
        default: false
      },
      overlayUid: String,
      parentUid: String
    },
    emits: ['deleted', 'added'],
    data() {
      return {
        isEditable: false,
        isMenuVisible: false,
        isReporting: false,
        isDeleting: false,
        isSaving: false,
        isLiking: false,
        isReplying: false,
        liked: this.comment ? this.comment.liked : null,
        likes: this.comment ? parseInt(this.comment.likes) : null,
        form: {
          cta: 'Post reply',
          classes: 'grid grid-cols-1 sm:grid-cols-2 sm:gap-x-8 pl-2 flex-1 mt-1 relative',
          initial: {
            comment: null,
            reply_to_uid: null
          },
          fields: [
            {
              id: 'reply',
              elem: 'textarea',
              type: 'text',
              placeholder: 'Write a reply…',
              isRequired: 'true',
              classes: 'sm:col-span-2',
              inputClasses: 'border-gray-100 bg-gray-100',
              small: true
            },
            {
              id: 'reply_to_uid',
              elem: 'input',
              type: 'hidden',
              isRequired: 'true',
              hidden: true,
              disabled: true
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      content() {
        const filter = new Filter()
        const plaintoAnchors = linkify(this.comment.comment)
        const textFilter = this.comment.comment.replace(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
          ''
        )

        return !!plaintoAnchors
          ? textFilter.length > 2
            ? filter.clean(plaintoAnchors).replace(/\n/g, '<br />')
            : plaintoAnchors.replace(/\n/g, '<br />')
          : null
      },
      date() {
        return timeago.format(this.comment.created_at, 'en-short')
      },
      hasPhoto() {
        return this.comment.user_cover_photo
      },
      hasCoverPhoto() {
        return this.loggedInUser.cover_photo
      },
      coverPhoto() {
        const user = this.loggedInUser

        return user.cover_photo
          ? `${process.env.VUE_APP_IMGIX}/users/${user.id}/${user.cover_photo}?w=64&h=64&fit=crop`
          : null
      },
      userPhoto() {
        const comment = this.comment

        return comment.user_cover_photo
          ? `${process.env.VUE_APP_IMGIX}/users/${comment.user_uid}/${comment.user_cover_photo}?w=64&h=64&fit=crop`
          : null
      },
      hasLikes() {
        return !!this.likes
      },
      hasReplies() {
        return this.comment.replies && !!this.comment.replies.length
      },
      isCommentAuthor() {
        return this.loggedInUser.id === this.comment.user_uid
      }
    },
    methods: {
      async deleteComment() {
        this.isDeleting = true

        try {
          await this.apiRequest(
            `/comment/${this.comment.uid}`,
            null,
            null,
            {
              Authorization: this.userJWT
            },
            'DELETE'
          )

          // this.$store.dispatch('setDialog', {
          //   title: 'Comment Deleted',
          //   content: '<p>Your comment has been deleted.</p>',
          //   ctaTitle: 'Close',
          //   confirmed: true
          // })

          this.$emit('deleted', this.comment.uid)
        } catch (e) {
          if (e) console.log(`Error deleting comment: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this comment. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isDeleting = false
        this.isMenuVisible = false
      },
      async reportComment() {
        try {
          this.isReporting = true

          await this.apiRequest(
            '/report',
            {
              template: 'report_comment',
              content: {
                comment: {
                  content: this.comment.comment,
                  url: this.$route.fullPath.substring(1),
                  first_name: this.comment.first_name,
                  last_name: this.comment.last_name,
                  username: this.comment.username
                },
                user: {
                  first_name: this.loggedInUser.first_name,
                  last_name: this.loggedInUser.last_name,
                  username: this.loggedInUser.username
                }
              },
              verification: this.loggedInUser.id
            },
            null,
            {
              Authorization: this.userJWT
            },
            'POST'
          )

          this.$store.dispatch('setDialog', {
            title: 'Report Sent',
            content: '<p>Thanks for reporting this comment.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })
        } catch (e) {
          if (e) console.log(`Error reporting comment: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't report this comment. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isReporting = false
        this.isMenuVisible = false
      },
      async likeComment() {
        this.isMenuVisible = false

        try {
          this.isLiking = true

          await this.apiRequest(
            `/like`,
            this.liked
              ? {
                  u: this.comment.uid
                }
              : {
                  comment_uid: this.comment.uid,
                  n: this.comment.user_uid,
                  parent_uid: this.parentUid,
                  overlay_uid: this.overlayUid
                },
            null,
            {
              Authorization: this.userJWT
            },
            this.liked ? 'DELETE' : 'POST'
          )

          this.isLiking = false

          this.liked = !this.liked
          this.likes = this.liked ? this.likes + 1 : this.likes - 1
        } catch (e) {
          if (e) console.log(`Error liking comment: ${e.status} ${e.data}`)
        }
      },
      toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible
      },
      async toggleReplyComment() {
        this.isReplying = !this.isReplying
        this.isMenuVisible = false
        if (this.isReplying) this.form.initial.reply_to_uid = this.comment.uid
      },
      async toggleEditComment() {
        const el = this.$refs.Comment

        if (!this.isEditable) {
          this.isEditable = true
          this.isMenuVisible = !this.isMenuVisible

          setTimeout(function () {
            setFocus(el)
          }, 0)

          el.addEventListener('keydown', this.editComment, true)
        } else {
          this.undoEdit()
          el.removeEventListener('keydown', this.editComment, true)
        }
      },
      async editComment(e) {
        if (e.keyCode == 27) {
          this.undoEdit()
        } else if (e.keyCode == 13 && !e.shiftKey) {
          e.preventDefault()

          const text = e.target.innerHTML.replace(/(<([^>]+)>)/gi, '')
          const data = {
            contentUid: this.comment.content_uid,
            comment: e.target.innerHTML.replace(/<br\s*[\/]?>/gi, '\n').replace(/(<([^>]+)>)/gi, ''),
            reply: null
          }

          try {
            if (!!!text.length) throw new Error()

            await this.apiRequest(`/comment/${this.comment.uid}`, data, null, { Authorization: this.userJWT }, 'PUT')

            this.$emit('added')
            this.isEditable = false
          } catch (e) {
            this.$store.dispatch('setDialog', {
              title: 'Error',
              content:
                e && e.data && e.data.message
                  ? `<p>${e.data.message}</p>`
                  : "<p>Sorry we couldn't edit your comment</p>",
              ctaTitle: 'Close',
              error: true
            })
          }
        }
      },
      undoEdit() {
        this.isEditable = false
        this.$refs.Comment.innerHTML = `<p>${this.content}</p>`
      },
      async submitForm(values) {
        this.isSaving = true

        try {
          await this.apiRequest(
            `/comment`,
            {
              contentUid: this.comment.content_uid,
              comment: values.reply,
              reply: values.reply_to_uid,
              n: this.comment.user_uid
            },
            null,
            { Authorization: this.userJWT },
            'POST'
          )

          this.$emit('added')
        } catch (e) {
          this.form.initial.comment = e.data.comment

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: e ? `<p>${e.data.message}</p>` : "<p>Sorry we couldn't post your comment</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isSaving = false
        this.isReplying = false
      },
      async updateCommentList(replyUid) {
        this.$emit('deleted', replyUid)
      }
    }
  }
</script>
