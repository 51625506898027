<template>
  <li
    v-if="event"
    :class="['relative group', full ? 'px-4 lg:px-8 pt-4' : null, !last ? 'border-b border-gray' : null]"
  >
    <div v-if="full" class="mx-auto max-w-screen-2xl">
      <RouterLink
        :to="eventLink"
        :class="[
          'bg-gray block relative w-full overflow-hidden',
          photoUrl ? 'aspect-w-3 aspect-h-2' : 'aspect-w-16 aspect-h-3'
        ]"
      >
        <img
          v-if="photoUrl"
          :class="[
            'absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300',
            !isImageLoaded ? 'opacity-0' : 'opacity-100'
          ]"
          :src="photoUrl"
          :alt="event.circuit"
          @load="imageLoaded"
        />
        <div class="flex flex-col items-center justify-center bg-gray">
          <AtomLogo />
          <!-- <p class="font-bold text-3xl mt-4 lg:mt-8">
            Tag yourself at this event
          </p> -->
        </div>
      </RouterLink>
    </div>
    <div :class="['p-4', full ? 'px-0 lg:px-0' : preview ? '' : 'lg:px-8']">
      <div
        class="relative z-20 mx-auto max-w-screen-2xl"
        :class="[preview ? 'flex w-full justify-between' : 'lg:flex lg:w-full']"
      >
        <RouterLink
          :to="eventLink"
          class="absolute z-10 left-0 top-0 w-full h-full focus:outline-none"
          title="View event details"
        />
        <div class="flex items-center lg:flex-1">
          <!-- <div
            class="flex items-center justify-center overflow-hidden h-14 w-14 absolute top-0 left-0 lg:relative lg:w-20 lg:h-20"
          >
            <AtomLogo />
          </div> -->
          <!-- <div class="lg:ml-2"> -->
          <div>
            <!-- <h3
              class="h-12 font-bold text-black text-lg ml-16 mb-2 lg:mb-1 lg:h-auto pl-1 lg:ml-0 lg:text-xl lg:pl-0"
            > -->
            <h3 class="mb-2 text-black text-lg font-bold lg:mb-1 lg:text-xl">
              {{ event.circuit }}
              <template v-if="!preview && event.layout !== 'Full Circuit'">
                <br class="lg:hidden" />
                <span class="hidden ml-1 mr-2 lg:inline-block">-</span>{{ event.layout }}
              </template>
              <template v-if="isEveningEvent"> (Evening)</template>
            </h3>
            <p class="text-base lg:absolute lg:right-0 lg:top-1">
              <AtomFormattedDate :date="event.start_date"></AtomFormattedDate>
            </p>
            <p class="lg:mt-1">
              <RouterLink
                class="transition-colrs lg:hover:text-orange-500 relative z-30 duration-150 ease-in-out"
                :to="organiserLink"
              >
                {{ event.organiser }}
              </RouterLink>
            </p>
          </div>
        </div>
        <ul
          class="
            grid
            gap-2
            justify-end
            sm:gap-4
            lg:flex lg:flex-1
            lg:gap-2
            lg:grid-cols-3
            lg:items-center
            lg:mt-2 lg:mt-8
          "
          :class="[preview ? 'w-8 grid-cols-1 items-end' : 'mt-4 w-full grid-cols-2']"
        >
          <li
            v-if="event.friends_attending && loggedInUser"
            class="
              flex flex-1
              col-span-2
              row-start-3
              items-center
              sm:col-start-1
              sm:row-start-2
              lg:flex-row-reverse
              lg:col-start-1
              lg:row-start-1
              lg:justify-start
              lg:mr-2
              lg:text-right
            "
          >
            <RouterLink
              v-for="friend in friends"
              :key="friend.uid"
              :to="`/${friend.username}`"
              class="relative z-20 inline-block -mr-2 w-8 h-8 bg-gray rounded-full"
              :title="`View ${friend.username}'s profile`"
            >
              <img
                v-if="friend.photo"
                class="inline-block w-8 h-8 border border-white rounded-full"
                :src="userPhoto(friend.uid, friend.photo)"
                :alt="friend.username"
                height="32"
                width="32"
              />
            </RouterLink>
            <p class="ml-4 mx-2">{{ friendsGoing }}</p>
          </li>
          <li v-if="!full && !simple && !preview" class="lg:flex lg:col-start-3 lg:items-start lg:justify-end">
            <button
              :class="[
                'w-full flex items-center justify-center px-3 h-8 border border-transparent text-sm focus:outline-none leading-6 font-medium rounded-sm transition duration-300 ease-in-out relative z-20',
                !isAttending
                  ? 'bg-gray text-black lg:hover:bg-orange-500 lg:hover:text-white'
                  : 'text-white bg-orange-500 lg:hover:bg-black'
              ]"
              @click.prevent="updateAttendingStatus"
            >
              <span v-if="!isUpdatingStatus" class="pointer-events-none">
                <span v-if="!isAttending">{{ attending }} </span>
                <span v-else>{{ attended }}</span>
              </span>
              <AtomLoader v-else></AtomLoader>
            </button>
          </li>
          <li class="h-8 lg:w-8">
            <RouterLink
              :to="eventLink"
              class="
                lg:group-hover:border-orange-500 lg:group-hover:bg-orange-500 lg:group-hover:text-white
                flex
                items-center
                justify-center
                h-8
                text-black text-sm
                font-medium
                leading-6
                border border-gray
                rounded-sm
                focus:outline-none
                transition
                duration-300
                ease-in-out
                lg:w-8
                lg:rounded-full
              "
              :class="[preview ? 'h-8 w-8 rounded-full' : 'w-full']"
              title="View event details"
            >
              <span class="lg:hidden" :class="[preview ? 'hidden' : null]">View details</span>
              <BaseIcon class="w-7 h-7" :class="[!preview ? 'hidden lg:flex' : 'flex']">
                <!-- full ? 'transform-gpu rotate-90' : null -->
                <ChevronRightIcon />
                <!-- <DotsIcon v-else></DotsIcon> -->
              </BaseIcon>
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      event: Object,
      last: Boolean,
      full: Boolean,
      preview: Boolean,
      simple: Boolean
    },
    data() {
      return {
        isAttending: false,
        isUpdatingStatus: false,
        isImageLoaded: false
      }
    },
    // emits: ['delete-event'],
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      eventLink() {
        return `/${this.event.series ? 'race' : 'event'}/${this.event.slug}`
      },
      organiserLink() {
        return `/organiser/${this.event.organiser_slug}`
      },
      attended() {
        const eventDate = Date.parse(this.event.start_date)

        if (eventDate < Date.now()) {
          return 'You attended'
        } else {
          return "You're going"
        }
      },
      attending() {
        const eventDate = Date.parse(this.event.start_date)

        if (eventDate < Date.now()) {
          return 'Did you go?'
        } else {
          return 'Going?'
        }
      },
      friendsGoing() {
        const eventDate = Date.parse(this.event.start_date)

        if (this.event.friends_attending) {
          let friends

          if (eventDate < Date.now()) {
            friends = this.event.friends_attending
              ? this.event.friends_attending.length > 1
                ? 'friends went'
                : 'friend went'
              : null
          } else {
            friends = this.event.friends_attending
              ? this.event.friends_attending.length > 1
                ? 'friends are going'
                : 'friend is going'
              : null
          }

          return `${this.event.friends_attending.length} ${friends}`
        }
        return null
      },
      friends() {
        return this.event.friends_attending ? this.event.friends_attending : null
      },
      photoUrl() {
        return this.event && this.event.hero
          ? `${process.env.VUE_APP_IMGIX}/albums/${this.event.hero}?w=1536&ar=1.5&fit=crop`
          : null
      },
      isEveningEvent() {
        return this.event.slug.includes('evening')
      }
    },
    created() {
      this.checkAttendingStatus()
    },
    methods: {
      async checkAttendingStatus() {
        if (!this.loggedInUser) return false

        this.isAttending = this.event.is_attending
      },
      async updateAttendingStatus(event) {
        this.isUpdatingStatus = true
        event.target.style.width = `${event.target.offsetWidth}px`

        try {
          await this.apiRequest(
            `/event/${this.event.uid}/user`,
            null,
            null,
            { Authorization: this.userJWT },
            this.isAttending ? 'DELETE' : 'POST'
          )

          this.isAttending = !this.isAttending
        } catch (e) {
          if (e) console.log(`Error updating attending status: ${e.status} ${e.data}`)
        }

        event.target.style.width = null
        this.isUpdatingStatus = false
      },
      imageLoaded() {
        this.isImageLoaded = true
      },
      userPhoto(uid, filename) {
        return filename ? `${process.env.VUE_APP_IMGIX}/users/${uid}/${filename}?w=64&h=64&fit=crop` : null
      }
    }
  }
</script>
