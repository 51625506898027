<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    role="presentation"
    fill="currentColor"
  >
    <slot />
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String
      },
      viewBox: {
        type: String,
        default: '0 0 16 16'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      }
    }
  }
</script>
